#home .btn {
  width: 280px;
}

#home .btn-slide {
  width: 270px;
}

#home .btn-blue {
  background-color: #0088ab;
}
#home .btn-blue:hover {
  background-color: #0088ab;
  transition: 0.3s;
  opacity: 0.8;
}

#home .btn-red {
  background-color: #f13a1a;
}
#home .btn-red:hover {
  background-color: #f13a1a;
  transition: 0.3s;
  opacity: 0.8;
}

#home .btn-navy {
  background-color: #003542;
}
#home .btn-navy:hover {
  background-color: #003542;
  transition: 0.3s;
  opacity: 0.8;
}
